<template>
  <CCard>
    <CCardHeader> {{ $t(_.capitalize(options.name)) }} id: {{ options.id }} </CCardHeader>
    <CCardBody>
      <CDataTable striped small fixed :items="items" :fields="fields">
        <template slot="value" slot-scope="data">
          <td class="text-center">
            <strong>{{ data.item.value }}</strong>
          </td>
        </template>
      </CDataTable>
    </CCardBody>
    <CCardFooter>
      <CButton color="secondary" @click="goBack">{{ $t('Back') }}</CButton>
    </CCardFooter>
  </CCard>
</template>

<script>
export default {
  name: 'AItemViewer',
  props: {
    options: {
      type: Object,
      required: true
    }
  },
  data: () => {
    return {
      items: [],
      fields: [{ key: 'key' }, { key: 'value' }] // [{ key: this.$t('Key') }, { key: this.$t('Value') }]
    }
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    }
  },
  mounted() {
    this.$http
      .get(`${this.options.url}/${this.options.id}?no-extra`)
      .then(response => {
        const items = Object.entries(response.data[this.options.name])
        this.items = items.map(([key, value]) => {
          return { key: this.$t(this._humanize(key)), value }
        })
      })
      .catch(error => {
        console.error(error)
        //this.$router.push({ path: '/login' })
      })
  }
}
</script>
